<template>
  <b-button
    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
    v-b-modal.initiate-payment
    class="mr-2"
    variant="outline-warning"
  >
    <feather-icon
      icon="DollarSignIcon"
      class="mr-50"
    />
    <span class="align-middle">Pay</span>
    <b-overlay
      :show="showWaitingPayment"
      rounded="sm"
    >
      <b-modal
        id="initiate-payment"
        ref="my-modal"
        :title="`Pay for Invoice No - ${businessInvoiceData.invoice.invoiceNo}`"
        ok-title="Pay"
        cancel-variant="outline-secondary"
        centered
        @show="resetModal"
        @hidden="resetModal"
        @ok="handleSubmit"
      >
        <validation-observer ref="simpleRules">
          <statistic-card-horizontal
            icon="DollarSignIcon"
            color="success"
            :statistic="`Ksh ${businessInvoiceData.amount}`"
          />
          <form
            ref="form"
            @submit.stop.prevent="handleSubmit"
          >
            <!-- Phone No -->
            <b-form-group
              label="Phone No"
              label-for="phoneNo"
            >
              <validation-provider
                #default="{ errors }"
                name="Phone No"
                rules="required"
              >
                <b-form-input
                  id="phoneNo"
                  v-model="phoneNo"
                  type="text"
                  :state="errors.length > 0 ? false:null"
                  placeholder="Phone No"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </form>
        </validation-observer>
      </b-modal>
    </b-overlay>
  </b-button>
  <!-- modal -->
</template>

<script>
import {
  BButton, BFormGroup, BFormInput, BModal, VBModal, BOverlay,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import Ripple from 'vue-ripple-directive'
import appUtils from '@/auth/jwt/useJwt'
import { required } from '@validations'
import StatisticCardHorizontal from '@core/components/statistics-cards/StatisticCardHorizontal.vue'

export default {
  components: {
    BButton,
    BFormGroup,
    BFormInput,
    BModal,
    BOverlay,
    ValidationProvider,
    ValidationObserver,
    StatisticCardHorizontal,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  props: {
    businessInvoiceData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showWaitingPayment: false,
      phoneNo: null,
      required,
      connection: null,
      iData: this.businessInvoiceData,
    }
  },
  methods: {
    resetModal() {
      this.phoneNo = null
    },
    handleSubmit(ev) {
      // Prevent modal from closing
      ev.preventDefault()
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          // Trigger submit handler
          // eslint-disable-next-line
          appUtils.payForSbp({
            phoneNo: this.phoneNo,
            invoiceId: this.iData.invoice.id,
            invoiceNo: this.iData.invoice.invoiceNo,
            amount: this.iData.amount,
          })
            .then(response => {
              if (response.data.status === '00') {
                this.$swal({
                  title: 'Success!',
                  text: response.data.message,
                  icon: 'success',
                  customClass: {
                    confirmButton: 'btn btn-primary',
                  },
                  buttonsStyling: false,
                })
              } else {
                this.$swal({
                  title: 'Error!',
                  text: response.data.message,
                  icon: 'error',
                  customClass: {
                    confirmButton: 'btn btn-primary',
                  },
                  buttonsStyling: false,
                })
              }
            })
        }
      })
    },
  },
}
</script>
