import { ref, watch, computed } from '@vue/composition-api'

export default function usePaymentsList() {
  const refPaymentsListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'receiptNo', label: 'Receipt No', sortable: true },
    {
      key: 'amount', label: 'Amount', sortable: true, formatter: val => `${val}`,
    },
    {
      key: 'transactionTime', label: 'Payment Date', sortable: true, formatter: val => `${val.substr(0, 10)}`,
    },
    {
      key: 'year', label: 'SBP year', sortable: true,
    },
    { key: 'actions', label: 'Download' },
  ]
  const perPage = ref(10)
  const totalInvoices = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const statusFilter = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refPaymentsListTable.value ? refPaymentsListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalInvoices.value,
    }
  })

  const refetchData = () => {
    refPaymentsListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, statusFilter], () => {
    refetchData()
  })

  return {
    tableColumns,
    perPage,
    currentPage,
    totalInvoices,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refPaymentsListTable,

    refetchData,
  }
}
