<template>
  <div>

    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="businessData === undefined"
    >
      <h4 class="alert-heading">
        Error fetching Business Data
      </h4>
      <div class="alert-body">
        No Business found with this Business ID. Check the list
        <b-link
          class="alert-link"
          :to="{ name: 'services-sbp'}"
        >
          List of active Businesses
        </b-link>
        assigned to you
      </div>
    </b-alert>

    <template v-if="businessData">
      <!-- First Row -->
      <b-row class="match-height">
        <b-col
          cols="12"
        >
          <business-info-card :business-data="businessData" />
        </b-col>
        <!-- <b-col
          cols="12"
          lg="6"
        >
          <business-notifications-card />
        </b-col> -->
      </b-row>
      <b-row>
        <b-col
          cols="12"
          lg="6"
        >
          <invoices-list :invoices-data="businessData.data.invoices" />
        </b-col>
        <b-col
          cols="12"
          lg="6"
        >
          <payments-list :payment-data="businessData.data.payments" />
        </b-col>
      </b-row>
    </template>

  </div>
</template>

<script>
import router from '@/router'
import { ref } from '@vue/composition-api'
import {
  BRow, BCol, BAlert, BLink,
} from 'bootstrap-vue'
import AppUtils from '@/auth/jwt/useJwt'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import InvoicesList from './InvoicesList.vue'
import PaymentsList from './PaymentsList.vue'
import BusinessInfoCard from './BusinessInfoCard.vue'
// import BusinessNotificationsCard from './BusinessNotificationsCard.vue'

export default {
  components: {
    BRow,
    BCol,
    BAlert,
    BLink,

    // Local Components
    BusinessInfoCard,
    // BusinessNotificationsCard,

    InvoicesList,
    PaymentsList,
  },
  setup() {
    const businessData = ref(null)

    AppUtils.fetchBusinessData({ businessId: router.currentRoute.params.id })
      .then(response => { businessData.value = response.data })
      .catch(error => {
        if (error.response.status === 404) {
          businessData.value = undefined
        }
        let message

        if (error.response) {
          message = error.response.data.message
        } else if (error.request) {
          message = 'No response received from server'
        } else {
          message = 'An error has occurred'
        }
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'SBP Error',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: `Error: ${message}`,
          },
        })
      })

    return {
      businessData,
    }
  },
}
</script>

<style>

</style>
