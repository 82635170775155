<template>

  <!-- Table Container Card -->
  <b-card
    title="Payments Table"
    no-body
  >

    <b-table
      ref="refPaymentsListTable"
      :items="paymentData"
      responsive
      :fields="tableColumns"
      primary-key="id"
      :sort-by.sync="sortBy"
      show-empty
      empty-text="No matching records found"
      :sort-desc.sync="isSortDirDesc"
      class="position-relative"
    >

      <template #head(paymentStatus)>
        <feather-icon
          icon="TrendingUpIcon"
          class="mx-auto"
        />
      </template>

      <!-- Column: Id -->
      <template #cell(id)="data">
        <b-link
          :to="{ name: 'apps-invoice-preview', params: { id: data.item.id }}"
          class="font-weight-bold"
        >
          #{{ data.value }}
        </b-link>
      </template>

      <!-- Column: Invoice Status -->
      <template #cell(paymentStatus)="data">
        <b-avatar
          :id="`invoice-row-${data.item.id}`"
          size="32"
          :variant="`light-${resolveInvoiceStatusVariantAndIcon(data.item.invoiceStatus).variant}`"
        >
          <feather-icon
            :icon="resolveInvoiceStatusVariantAndIcon(data.item.invoiceStatus).icon"
          />
        </b-avatar>
        <b-tooltip
          :target="`invoice-row-${data.item.id}`"
          placement="top"
        >
          <p class="mb-0">
            {{ data.item.invoiceStatus }}
          </p>
          <p class="mb-0">
            Balance: {{ data.item.balance }}
          </p>
          <p class="mb-0">
            Due Date: {{ data.item.dueDate }}
          </p>
        </b-tooltip>
      </template>

      <!-- Column: Invoice No -->
      <template #cell(receiptNo)="data">
        <span class="text-nowrap">
          {{ data.value }}
        </span>
      </template>

      <!-- Column: Issued Date -->
      <template #cell(paymentDate)="data">
        <span class="text-nowrap">
          {{ data.value }}
        </span>
      </template>

      <!-- Column: SBP year -->
      <template #cell(year)="data">
        <span class="text-nowrap">
          {{ data.value }}
        </span>
      </template>

      <!-- Column: Actions -->
      <template #cell(actions)="data">

        <div class="text-nowrap">
          <b-button
            class="mr-2"
            variant="outline-warning"
            @click="downloadReceipt(data.item)"
          >
            <feather-icon
              icon="SquareIcon"
              class="mr-20"
            />
            <span class="align-middle">Receipt</span>
          </b-button>
          <b-button
            variant="outline-success"
            @click="downloadPermit(data.item)"
          >
            <feather-icon
              icon="FileTextIcon"
              class="mr-20"
            />
            <span class="align-middle">Permit</span>
          </b-button>
        </div>
      </template>

    </b-table>
  </b-card>

</template>

<script>
import {
  BCard, BTable, BAvatar, BButton, BLink, BTooltip,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import AppUtils from '@/auth/jwt/useJwt'
import usePaymentsList from './usePaymentsList'

export default {
  components: {
    BCard,
    BButton,
    BTable,
    BAvatar,
    BLink,
    BTooltip,
  },
  props: {
    paymentData: {
      type: Array,
      required: true,
    },
  },
  methods: {
    downloadPermit(data) {
      AppUtils.downloadPermit(data.invoice.id)
    },
    downloadReceipt(data) {
      AppUtils.downloadReceipt(data.invoice.id)
    },
  },
  setup() {
    const {
      tableColumns,
      totalInvoices,
      dataMeta,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refPaymentsListTable,

      statusFilter,

      refetchData,

      resolveInvoiceStatusVariantAndIcon,
      resolveClientAvatarVariant,
    } = usePaymentsList()

    return {
      tableColumns,
      totalInvoices,
      dataMeta,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refPaymentsListTable,

      statusFilter,

      refetchData,

      avatarText,
      resolveInvoiceStatusVariantAndIcon,
      resolveClientAvatarVariant,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
